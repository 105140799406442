<template>
  <div class="email">
    <div v-if="!isEms">
      <div class="tit">{{ $t('reg_email_1') }}</div>
      <div class="tip tip-top">
        {{ $t('reg_email_2') }}
      </div>
      <div class="tip">
        {{ $t('reg_email_3') }}
      </div>
      <div class="tip">
        {{ $t('reg_email_4') }}
      </div>
      <div class="tip">
        {{ $t('reg_email_5') }}
      </div>
      <el-form
        :model="ruleForm"
        label-width="140px"
        ref="ruleForm"
        label-position="left"
        :hide-required-asterisk="true"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('reg_email_6')"
          :rules="[
            { required: true, message: $t('reg_email_7'), trigger: 'blur' },
            {
              type: 'email',
              message: $t('reg_email_8'),
              trigger: ['blur'],
            },
          ]"
          prop="email"
        >
          <el-input
            v-model="ruleForm.email"
            @keyup.enter.native="emsSend"
            :placeholder="$t('reg_email_9')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="textCenter">
        <el-button @click="emsSend" :loading="nextLoading" type="primary">
          {{ $t('reg_email_10') }}
        </el-button>
        <div class="tip" @click="toLogin">{{ $t('reg_email_11') }}</div>
      </div>
    </div>
    <div class="emsOk" v-else>
      <i class="el-icon-success"></i>
      <div class="emsTip">{{ $t('reg_email_12') }}{{ ruleForm.email }}</div>
    </div>
  </div>
</template>

<script>
import { getUrlArgs } from '@/utils/tool.js'
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
        email: '',
        event: 'register',
      },
      isEms: false,
      nextLoading: false,
    }
  },
  created() {
    let invite_id = ''
    if (getUrlArgs('id')) {
      invite_id = getUrlArgs('id')
    } else if (this.$route.params.id) {
      invite_id = this.$route.params.id
    } else {
      invite_id = ''
    }
    this.ruleForm.invite_id = invite_id
  },
  methods: {
    toLogin() {
      this.$router.push({ name: 'Login' })
    },
    async emsSend() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post('/api/Ems/send', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            localStorage.removeItem('sm_token')
            localStorage.removeItem('sm_email')
            localStorage.removeItem('sm_type')
            localStorage.removeItem('sm_userinfo')
            if (res.data.send_status === 1) {
              this.isEms = true
            } else {
              localStorage.setItem('sm_token', res.data.token)
              localStorage.setItem('sm_email', res.data.email)
              localStorage.setItem('sm_type', res.data.type)
              localStorage.setItem('sm_userinfo', JSON.stringify(res.data))
              this.$router.push({ name: res.data.location_url })
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.email {
  width: 900px;
  margin: 50px auto 0;

  .tit {
    font-size: 36px;
    margin-bottom: 50px;
  }

  .tip {
    font-size: 14px;
    margin-bottom: 10px;

    &.tip-top {
      margin-bottom: 30px;
    }
  }

  .demo-ruleForm {
    width: 500px;
    margin-top: 30px;
  }

  .textCenter {
    margin-top: 30px;

    .tip {
      color: #409eff;
      margin-top: 20px;
      cursor: pointer;
    }
  }

  .emsOk {
    text-align: center;
    margin-top: 120px;

    i {
      font-size: 60px;
      color: #409eff;
    }

    .emsTip {
      font-size: 14px;
      margin-top: 30px;
    }
  }
}
</style>
